import { type ReactNode } from 'react'
import { Heading, Text } from '@yms/hexagon'

import { PageRoot } from '../PageRoot'
import styles from './common.module.css'

export function ErrorPage({
  title,
  subtitle,
  children
}: {
  readonly title: string
  readonly subtitle?: string
  readonly children?: ReactNode
}) {
  return (
    <PageRoot className={styles.container}>
      <div className={styles.internal}>
        <Heading variant="heading1" color="base">
          {title}
        </Heading>
        {subtitle && (
          <Text color="weak" size="xs">
            {subtitle}
          </Text>
        )}
        {children}
      </div>
    </PageRoot>
  )
}
