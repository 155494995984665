import { useSuspenseQuery } from '@tanstack/react-query'
import { type QFilterExpression, createQueryBuilderV4 } from '@odata2ts/odata-query-builder'
import { QODataYardZoneDto } from '@yms/api/odata-client/QItgPltYmsApi'
import { type ODataYardZoneDto } from '@yms/api/client'
import { odataStringToRecord } from '@yms/api/odataStringToRecord'

import { useCurrentYardZonesIdsQuery } from '@mobile/auth/hooks/useCurrentYardZonesIdsQuery'

import { yardZonesApi } from './api'

export const yardZonesDetails = {
  queryFn: ({ signal, yardZoneIds }: { signal?: AbortSignal; yardZoneIds: string[] | null }) => {
    const qYardZone = new QODataYardZoneDto()
    const builder = createQueryBuilderV4('y', qYardZone)

    let yardZoneIdsFilter: QFilterExpression | null = null
    if (yardZoneIds) {
      for (const id of yardZoneIds) {
        if (!yardZoneIdsFilter) {
          yardZoneIdsFilter = qYardZone.id.equals(id)
        } else {
          yardZoneIdsFilter = yardZoneIdsFilter.or(qYardZone.id.equals(id))
        }
      }
    }
    const query = builder.select('name', 'siteCode').filter(yardZoneIdsFilter).build()
    const odataRecord = odataStringToRecord(query)

    return yardZonesApi.getODataYardZones(odataRecord, { signal }).then(r => r.data.value ?? [])
  },
  queryKey: (yardZoneIds: string[] | null) => ['yard-zones', { ids: yardZoneIds }],
  select: (data: Partial<ODataYardZoneDto>[] | undefined) => {
    const map = new Map<string, string[]>()
    for (const item of data ?? []) {
      const entry = map.get(item.siteCode ?? '') ?? []
      entry.push(item.name!)
      map.set(item.siteCode ?? '', entry)
    }

    return Array.from(map)
  }
}

export function useYardZonesDetailsQuery() {
  const yardZoneIds = useCurrentYardZonesIdsQuery()

  const query = useSuspenseQuery({
    queryKey: yardZonesDetails.queryKey(yardZoneIds),
    queryFn: ({ signal }) => yardZonesDetails.queryFn({ signal, yardZoneIds }),
    select: yardZonesDetails.select
  })

  return query.data
}
