import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import { Heading, Text, MaybeLink } from '@yms/hexagon'
import { error } from '@yms/common/logging'

import { ErrorPage } from '../ErrorPage/ErrorPage'
import { getErrorMessage } from '../ErrorPage/getErrorMessage'
import { Layout } from './Layout'
import styles from './Layout.errorPage.module.css'

export function LayoutErrorPage() {
  const routeError = useRouteError()

  useEffect(() => {
    if (routeError) {
      error('Application Crash', new Error('Application Crash', { cause: routeError }))
    }
  }, [routeError])

  return (
    <Layout>
      <div className={styles.box}>
        <div className={styles.end}>
          <ErrorPage title="errors.unexpectedError">
            <Text>
              Something went wrong, we may be experiencing technical issues. <br />
              Try refreshing the page, if the error persists, please create an incident{' '}
              <MaybeLink
                data-testid="link-here"
                href="https://support.itglo.net/WorkOrder.do?woMode=newWO&from=Templates&module=incident&reqTemplate=14759"
              >
                here.
              </MaybeLink>
            </Text>
          </ErrorPage>
        </div>
        <div className={styles.center}>
          <div className={styles.stackTrace}>
            <Heading variant="heading4" color="weak">
              Stack trace
            </Heading>
            {getErrorMessage(routeError)}
          </div>
        </div>
      </div>
    </Layout>
  )
}
