import { type ReactNode } from 'react'

export type MaybeLinkProps = {
  readonly disabled?: boolean
  readonly children: ReactNode
  readonly onClick?: () => void
  readonly href?: string
}

export const MaybeLink = ({ disabled, children, ...props }: MaybeLinkProps) => {
  if (disabled) return children

  return <hx-link {...props}>{children}</hx-link>
}
