import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { createQueryBuilderV4 } from '@odata2ts/odata-query-builder'
import { QODataYardZoneDto } from '@yms/api/odata-client/QItgPltYmsApi'
import { type ODataYardZoneDto } from '@yms/api/client'
import { odataStringToRecord } from '@yms/api/odataStringToRecord'
import { arrayToEqualsFilter } from '@yms/api/odataUtilities'

import { useCurrentOperatorQuery } from '@mobile/auth/hooks/useCurrentOperatorQuery'
import { yardZonesApi } from '@mobile/api/yard-zones/api'

const INITIAL_VISIBLE = 3
const PAGE_SIZE = 6

export const useYardQuery = ({ open }: { open: boolean }) => {
  const currentOperator = useCurrentOperatorQuery()
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (!open) {
      setPage(0)
    }
  }, [open])

  const query = useQuery({
    placeholderData: [] as ODataYardZoneDto[],
    queryKey: ['yard-zones', 'options', { yardZoneIds: currentOperator.yardZoneIds }],
    queryFn: ({ signal }) => {
      const yz = new QODataYardZoneDto()
      const builder = createQueryBuilderV4('s', yz)
      const query = builder
        .top(100)
        .select('id', 'name')
        .filter(arrayToEqualsFilter(yz.id, currentOperator.yardZoneIds), yz.isActive.eq(true))
        .orderBy(yz.name.asc())
        .build()

      return yardZonesApi
        .getODataYardZones(odataStringToRecord(query), { signal })
        .then(r => r.data?.value ?? [])
    },
    select(data) {
      return data.map(({ name }) => ({ label: name, value: name }))
    }
  })

  const visibleSlice = query.data?.slice(0, INITIAL_VISIBLE + page * PAGE_SIZE) ?? []

  const canShowMore = () => {
    return query.isFetched && (query.data?.length ?? 0) > INITIAL_VISIBLE + page * PAGE_SIZE
  }

  const showMore = () => {
    setPage(page => page + 1)
  }

  return { query, total: query.data?.length ?? 0, visibleSlice, canShowMore, showMore }
}
