import { type ReactNode, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { type QueryClient, useQueryClient } from '@tanstack/react-query'
import { LoadingOverlay } from '@yms/common/components/LoadingOverlay'
import { PushNotificationType } from '@yms/api/client'
import { dispatchAction } from '@yms/common/Actions'
import { type Dialog } from '@yms/common/providers/DialogProvider'
import { Button } from '@yms/hexagon'

import { useNotificationRegistration } from '@mobile/notifications/useNotificationRegistration'
import { useNotificationHandler } from '@mobile/notifications/notificationHandler'
import { yardTaskDetails } from '@mobile/modules/main/common/useYardTaskDetailsQuery'
import { shippingUnitDetails } from '@mobile/modules/main/pages/shipping-units-details/useShippingUnitDetailsQuery'
import { PrefetchProvider } from '@mobile/prefetch/PrefetchProvider'
import { prefetch } from '@mobile/prefetch'
import { userManager } from '@mobile/auth/userManager'
import { type MessagePayload } from '@mobile/notifications/setupNotifications'

import { MobileLayout } from '../MobileLayout'

type NotificationData = { entityIds: string[] }

type LayoutProps = {
  readonly children?: ReactNode
}

function notificationHandler({ notificationType, data }: MessagePayload, queryClient: QueryClient) {
  switch (notificationType) {
    case PushNotificationType.YardTaskChanged: {
      queryClient.invalidateQueries({ queryKey: ['yard-tasks'] })
      queryClient.invalidateQueries({ queryKey: ['tasks', 'table'] })
      for (const taskId of (data as NotificationData).entityIds) {
        queryClient.invalidateQueries({ queryKey: yardTaskDetails.queryKey(taskId) })
      }
      break
    }
    case PushNotificationType.YardOrderChanged: {
      // Invalidate the query for the list of shipping units
      queryClient.invalidateQueries({ queryKey: ['shipping-units'] })

      // Invalidate the queries for each specific shipping unit detail
      for (const id of (data as NotificationData).entityIds) {
        queryClient.invalidateQueries({ queryKey: shippingUnitDetails.queryKey(id) })
      }
      break
    }
    case PushNotificationType.OperatorDeactivated: {
      dispatchAction<Dialog>('dialog:open', {
        type: 'normal',
        id: 'operator-deactivated',
        title: 'warning',
        message: 'operatorDeactivatedMessage',
        content: (
          <Button
            data-testid="close"
            slot="primary-button"
            onClick={() => {
              prefetch.stop()
              queryClient.cancelQueries()
              queryClient.clear()
              userManager.signoutRedirect()
            }}
          >
            logout
          </Button>
        )
      })
      break
    }
    case PushNotificationType.GenericNotification: {
      console.info(`Generic "${notificationType}" notification type received`)
      break
    }
    default: {
      console.error(`Unknown "${notificationType}" notification type received`)
    }
  }
}

export function Layout({ children }: LayoutProps) {
  const queryClient = useQueryClient()
  useNotificationRegistration()

  useNotificationHandler(
    notification => notificationHandler(notification, queryClient),
    [queryClient]
  )

  return (
    <Suspense fallback={<LoadingOverlay visible />}>
      <MobileLayout>
        <Outlet />
        {children}
      </MobileLayout>
      <Suspense>
        <PrefetchProvider />
      </Suspense>
    </Suspense>
  )
}
