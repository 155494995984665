import { useEffect, useState } from 'react'
import { isString } from 'lodash-es'
import { useQuery } from '@tanstack/react-query'
import { createQueryBuilderV4 } from '@odata2ts/odata-query-builder'
import { QODataOperatorDto } from '@yms/api/odata-client/QItgPltYmsApi'
import { type ODataOperatorDto } from '@yms/api/client'
import { odataStringToRecord } from '@yms/api/odataStringToRecord'
import { arrayToEqualsFilter } from '@yms/api/odataUtilities'

import { operatorsApi } from '@mobile/api/operators'
import { useCurrentOperatorQuery } from '@mobile/auth/hooks/useCurrentOperatorQuery'

const INITIAL_VISIBLE = 3
const PAGE_SIZE = 6

export const useFinishedByQuery = ({ open }: { open: boolean }) => {
  const currentOperator = useCurrentOperatorQuery()
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (!open) {
      setPage(0)
    }
  }, [open])

  const siteCodes = currentOperator.yardZones.map(yz => yz.siteCode).filter(isString)

  const query = useQuery({
    placeholderData: [] as ODataOperatorDto[],
    queryKey: ['operators', 'options', { siteCodes }],
    queryFn: ({ signal }) => {
      const operator = new QODataOperatorDto()
      const builder = createQueryBuilderV4('s', operator)
      const query = builder
        .top(100)
        .select('id', 'firstName', 'lastName')
        .filter(operator.siteCodes.any(x => arrayToEqualsFilter(x.it, siteCodes)))
        .orderBy(operator.firstName.asc(), operator.lastName.asc())
        .build()

      return operatorsApi
        .getODataOperators(odataStringToRecord(query), { signal })
        .then(r => r.data?.value ?? [])
    },
    select(data) {
      return data
        .toSorted((a, b) => {
          if (a.id === currentOperator.operatorId) {
            return -1
          }
          if (b.id === currentOperator.operatorId) {
            return 1
          }

          return 0
        })
        .map(op => {
          const value = `${op.firstName} ${op.lastName}`
          if (op.id === currentOperator.operatorId) {
            return { label: 'Myself', value }
          }

          return { label: value, value }
        })
    }
  })

  const visibleSlice = query.data?.slice(0, INITIAL_VISIBLE + page * PAGE_SIZE) ?? []

  const canShowMore = () => {
    return query.isFetched && (query.data?.length ?? 0) > INITIAL_VISIBLE + page * PAGE_SIZE
  }

  const showMore = () => {
    setPage(page => page + 1)
  }

  return { query, total: query.data?.length ?? 0, visibleSlice, canShowMore, showMore }
}
